import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { EdetailDosingStyles } from "../styles/EdetailDosingStyles";
import { AudioTranscript } from "../components/AudioTranscript";
import {
  Container,
  ConvertedMarkdown,
  Footnotes,
  References
} from "../components";

const EdetailDosing = ({ data }) => {

  const [isHCP, setHCP] = useState(false);

  const dataQuery          = data.allMarkdownRemark.nodes[0],
      componentID          = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
      markdownHTML         = data.allMarkdownRemark.nodes[0].html,
	    audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
      isiSnippet           = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
      markdownID           = `ed-dosing-content`;

  const [trackWProps, setTrackWProps] = useState('default');
  const [transcriptState, setTranscriptState] = useState(false);

  useEffect(() => {
    if (isWindow) {
      let isHCP = sessionStorage.getItem("hcp");
      if (isHCP !== "true") {
        setHCP(false);
      } else {
        setHCP(true);
      }
    }
  }, [isHCP, setHCP]);

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        isiSnippet={isiSnippet}
        trackWProps={audioTracksWithProps[trackWProps]}
        setTrackWProps={setTrackWProps}
        transcriptState={transcriptState}
        setTranscriptState={setTranscriptState}
      >
        <EdetailDosingStyles id={`main`} className={`wrapper`}>
          <ConvertedMarkdown markdownID={markdownID} mdhtml={markdownHTML} />
          <Footnotes
            markdownID={markdownID}
            footnotes={dataQuery.frontmatter.footnotes}
          />
          <References
            markdownID={markdownID}
            references={dataQuery.frontmatter.references}
          />
          <AudioTranscript id={`audio-transcript-main`} transcriptState={transcriptState} markdownID={markdownID} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
        </EdetailDosingStyles>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "ed-dosing-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          isiSnippet
          footnotes
          references
		  audioTracksWithProps {
			  default {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
		  }
        }
        id
        html
      }
    }
  }
`;

export default EdetailDosing;
